<template>
  <div class="mobelPage">
    <div class="header">
      <!-- 导航栏 -->
      <van-sticky>
        <div class="nav">
          <van-image @click="show = true" :src="require('@/assets/image/icon_menu_hover.png')" fit="cover"></van-image>
          <span>世纪银通</span>
        </div>
      </van-sticky>
      <!-- 轮播图 -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in binnerList" :key="index">
          <van-image height="60vh" :src="item.path" fit="cover" />
          <div class="text">{{item.title}}</div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 主体内容 -->
    <div class="content">
      <van-tabs color="#0052d9" line-width=".6rem" scrollspy>
        <!-- 公司简介 -->
        <van-tab title="公司简介">
          <div class="moduel" id="introduce">
            <div class="title">公司简介</div>
            <div class="text">
              <p class="textItem indent">四川世纪银通科技有限公司2012年9月于成都成立，注册资本四千万元。</p>
              <p class="textItem indent">自成立以来，深耕电信运营商业务，从为运营商提供技术及营销服务，到与运营商共同创新开展消费分期、供应链金融、融资租赁等业务。是中国移动、中国电信、中国联通三大运营商消费分期领域的战略合作伙伴。</p>
              <p class="textItem indent">世纪银通始终秉承“专注、专业、创新、满意”的企业核心理念，以广泛的客户渠道，专业的顾客服务，诚信的经营口碑，有力的合作伙伴，在行业中名列前茅，是成都武侯区纳税大户，四川省高新技术企业。</p>
            </div>
          </div>
        </van-tab>
        <!-- 发展历程 -->
        <van-tab title="发展历程">
          <div class="moduel" id="honor">
            <div class="title">发展历程</div>
            <div class="steps">
              <van-steps direction="vertical" :tabActive="0" active-color="#0052d9">
                <van-step v-for="(item,index) in stepList" :key="index">
                  <h3>{{item.title}}</h3>
                  <p v-for="(ele,index) in item.stepContent" :key="index">{{ele}}</p>
                </van-step>
              </van-steps>
            </div>
          </div>
        </van-tab>
        <!-- 企业文化 -->
        <van-tab title="企业文化">
          <div class="moduel" id="culture">
            <div class="title">企业文化</div>
            <div class="float">
              <div class="right">
                <van-image :src="require('@/assets/image/pic.jpg')" fit="contain"></van-image>
              </div>
              <div class="left">
                <div class="text">
                  <p class="title2">正直</p>
                  <p class="textItem">坚守底线，以德为先，坦诚公正不唯上</p>
                  <p class="title2">进取</p>
                  <p class="textItem">长期艰苦奋斗，坚持自我批判</p>
                  <p class="title2">协作</p>
                  <p class="textItem">团队合作，高效执行</p>
                  <p class="title2">诚信</p>
                  <p class="textItem">以诚相待、以信相交</p>
                </div>
              </div>
            </div>
          </div>
        </van-tab>

        <!-- 合作伙伴 -->
        <van-tab title="合作伙伴">
          <div class="moduel" id="partner">
            <div class="title">合作伙伴</div>
            <ul class="list">
              <li class="hvr-grow" v-for="(item,index) in partnerList" :key="index">
                <van-image style="height:.4rem;" :style="{ 'border-radius': item.type!='text' ? '50% ' : '','transform':'scale('+item.scale +')'}" :src="item.iconPath" fit="contain" />
                <!-- <p>{{item.name}}</p> -->
              </li>
            </ul>
          </div>
        </van-tab>
        <!-- 工作环境 -->
        <van-tab title="工作环境" @click.prevent="custormAnchor('contact')">
          <div class="moduel" id="environment">
            <div class="title">工作环境</div>
            <div class="workEnv">
              <van-image :src="require('@/assets/image/zlq.jpg')" fit="contain"></van-image>
              <div class="text">
                <p class="title2">成都棕榈泉国际中心</p>
                <p class="textItem">成都最高级别的国际标准超甲级写字楼之一，集数字化、智能化于一体的智慧大厦。</p>
              </div>
            </div>
          </div>
        </van-tab>
        <!-- 联系我们 -->
        <van-tab title="联系我们">
          <div class="moduel" id="contact">
            <div class="title">联系我们</div>
            <div class="float">
              <div class="text">
                <p class="title2">TEL</p>
                <p class="textItem">028-8544 2791</p>
                <p class="title2">地址</p>
                <p class="textItem">四川省成都市武侯区天府大道中段199号</p>
                <p class="title2">邮编</p>
                <p class="textItem">610041</p>
              </div>
              <div id="mapContainer"></div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

    </div>
    <!-- 回到顶部 -->
    <el-backtop></el-backtop>
    <!-- 弹出层 -->
    <van-popup v-model="show" position="left" closeable close-icon-position="top-left" :style="{ height:'100vh',width:'40vw'  }">
      <ul class="menu-item">
        <li class="hvr-underline-from-center" @click.prevent="custormAnchor('introduce')">简介</li>
        <li class="hvr-underline-from-center" @click.prevent="custormAnchor('culture')">企业文化</li>
        <li class="hvr-underline-from-center" @click.prevent="custormAnchor('honor')">发展历程</li>
        <li class="hvr-underline-from-center" @click.prevent="custormAnchor('partner')">合作伙伴</li>
        <li class="hvr-underline-from-center" @click.prevent="custormAnchor('contact')">联系我们</li>
      </ul>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false, // 弹出层
      binnerList: [
        { title: "专业 专注 创新 满意", content: "近40人的专业客服队伍，提供7*24小时优质服务。", path: require('@/assets/image/binner-3.png') },
        // { title: "消费金融产品推广", content: "借助世纪银通广泛的线下商家网点，为保险等金融产品提供落地推广能力。", path: require('@/assets/image/binner-2.jpg') },
      ],
      infoWindow: "",
      partnerList: [
        {
          iconPath: require('@/assets/image/yiPay.png'),
          scale: '.6'
        },
        {
          iconPath: require('@/assets/image/woPay.png'),
          scale: '0.9'
        },
        {
          iconPath: require('@/assets/image/yinLian.png'),
          scale: '.8'
        },
        {
          iconPath: require('@/assets/image/aliPay.png'),
          scale: '.7'
        },
        {
          iconPath: require('@/assets/image/huaBei.png'),
          scale: '.7'
        },
        {
          iconPath: require('@/assets/image/haiErJf.png'),
          scale: '1'
        },
        {
          iconPath: require('@/assets/image/zhiMa.png'),
          scale: '0.9'
        },
        {
          iconPath: require('@/assets/image/maYiJinFu.png'),
          scale: '.7'
        },
        {
          iconPath: require('@/assets/image/wangShangBink.png'),
          scale: '1'
        },
        {
          iconPath: require('@/assets/image/psbc_hLogo.png'),
          scale: '1.1'
        },

        {
          iconPath: require('@/assets/image/jsyh.png'),
          scale: '1'
        },
        {
          iconPath: require('@/assets/image/shYh.png'),
          scale: '0.9'
        },

      ],
      tabActive: 0, // tab栏
      active: 0, // 发展历程时间轴
      stepList: [
        {
          title: "2020", stepContent: [
            "与中国移动消费金融达成战略合作伙伴",
            '全面启动保理业务'
          ]
        },
        {
          title: "2019", stepContent: [
            '消金合作商家突破10000家',
            '全年消金业务放款达9亿',
          ]
        },
        {
          title: "2018", stepContent: [
            '引入战略合作伙伴山西通富集团',
            '向运营商消费分期业务转型',
            '信用购平台上线运营',
            '取得融资租赁和保理资质',
            '全年消金业务放款突破5000万',

          ]
        },
        {
          title: "2017", stepContent: [
            '全年充值缴费业务累计突破400亿',
            '获得国家科技部颁发的科技成果评价证书',
            '被列入“十三五”四川省“专精特新”中小企业（第一批）培育名单',
            '成都市“纳税大户”',
          ]
        },
        {
          title: "2015", stepContent: [
            '成都市“纳税大户”',
            '全年话费充值业务累计突破200亿',
            '自营渠道充值宝上线',
          ]
        },
        {
          title: "2014", stepContent: [
            '通过四川省高新技术企业认证',
            '全年话费充值业务累计突破100亿',
            '充值合作网点突破10000家',
            '与工商银行、红旗连锁、舞东风达成战略合作',
          ]
        },
        {
          title: "2013", stepContent: [
            '电信、移动、联通就话费充值业务达成合作',
            '取得第二类增值电信经营许可',
            '合作渠道商突破100家',
            '全年话费充值业务累计突破10亿',
            '314PAY电子支付平台上线',
            '武侯区纳税大户',
          ]
        },
        {
          title: "2012", stepContent: [
            '公司注册成立',
          ]
        },
      ]
    }
  },
  methods: {
    onClickRight() {
    }
  },
  mounted() {
    this.showMap();
    !(function (win, doc) {
      function setFontSize() {
        var winWidth = window.innerWidth;
        console.log(winWidth);
        doc.documentElement.style.fontSize = (winWidth / 375) * 100 + "px";
      }
      var evt = "onorientationchange" in win ? "orientationchange" : "resize";
      var timer = null;
      win.addEventListener(
        evt,
        function () {
          clearTimeout(timer);

          timer = setTimeout(setFontSize, 300);
        },
        false
      );
      win.addEventListener(
        "pageshow",
        function (e) {
          if (e.persisted) {
            clearTimeout(timer);

            timer = setTimeout(setFontSize, 300);
          }
        },
        false
      );
      //初始化
      setFontSize();
    })(window, document);
  },
  methods: {
    custormAnchor(index) { //锚点滚动到固定位置    
      this.show = false;
      let anchorElement = document.getElementById(index);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: "smooth"
        });
      }
    },
    showMap() {
      let map = new AMap.Map("mapContainer", {
        resizeEnable: true,
        center: [104.069793, 30.554029],
        zoom: 14
      });

      // 坐标点
      let marker = new AMap.Marker({
        position: new AMap.LngLat(104.069793, 30.554029), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "成都"
      });

      // 将创建的点标记添加到已有的地图实例：
      map.add(marker);
      // 同时引入工具条插件，比例尺插件和鹰眼插件
      AMap.plugin(
        [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.MapType",
        ],
        function () {
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          map.addControl(new AMap.ToolBar());

          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          map.addControl(new AMap.Scale());

          // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
          // map.addControl(new AMap.OverView({ isOpen: true }));

          // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
          // map.addControl(new AMap.MapType());

          // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
          map.addControl(new AMap.Geolocation());
        }
      );

      // 这里是信息窗体，不需要可以删除
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: this.createInfoWindow("服务区", ""),
        offset: new AMap.Pixel(16, -25)
      });
      this.infoWindow.setContent(
        this.createInfoWindow()
      );
      this.infoWindow.open(map, { Q: 30.554029, R: 104.069793, lng: 104.069793, lat: 30.554029 });
      marker.on("mouseover", e => {
        this.infoWindow.setContent(
          this.createInfoWindow(e.target.name,)
        );
        this.infoWindow.open(map, e.target.getPosition());
      });
    },
    // 自定义信息窗体
    createInfoWindow() {
      let info = `
    <div style="padding:.1rem; color:#0052d9; background:#fff; outline:0.01rem solid #0052d9; margin-bottom:0.04rem; text-align:left;">
        <div style="font-family:'HarmonyOsSansM'; font-style: italic; font-size:.18rem;margin-bottom:0.04rem; ">棕榈泉国际中心</div>
        <div style ="font-family:'HarmonyOsSansR'; color: #606266; font-weight: 500; font-size: .14rem;">成都市武侯区天府大道中段199号</div>
      </div>
     `
      return info;
    },
  }
}
</script>

<style lang="scss">
.mobelPage {
  font-size: 0.14rem;
  max-width: 100vw;
  min-height: 100vh;
  font-family: "HarmonyOsSansR";
  padding-bottom: 5vh;

  .header {
    position: relative;
    .my-swipe {
      height: 60vh;
      div.van-image {
        // width: 100%;
      }
    }
    div.nav {
      width: 100vw;
      background: #fff;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: 100%;
        line-height: 0.5rem;
        margin: 0 !important;
        font-size: 0.18rem;
        font-weight: bold;
        color: #0052d9;
        font-style: italic;
        text-align: center;
        font-family: "HarmonyOsSansR";
      }
      div.van-image {
        position: absolute;
        left: 0.1rem;
        top: 0.16rem;
        height: 0.2rem;
        width: 0.2rem;
      }
    }
    .text {
      width: 100%;
      position: absolute;
      left: 0;
      top: 2.44rem;
      font-size: 0.28rem;
      color: #fff;
      font-family: "HarmonyOsSansM";
      font-style: italic;
      text-align: center;
    }
  }

  .content {
    text-align: left;
    .moduel {
      padding: 0.2rem 0;
      background: #fbfbfb;
      .title {
        text-align: center;
        font-size: 0.22rem;
        font-weight: bold;
        padding: 0.1rem 0 0.2rem;
        color: #2a2e2e;
        font-style: italic;
        padding-top: 0.5rem;
      }
      .title::after {
        content: "";
        width: 8%;
        height: 0.02rem;
        display: block;
        padding-top: 0.06rem;
        margin: 0 auto;
        border-bottom: 0.03rem solid #0052d9;
      }
      div.text {
        text-align: left;
        color: #606266;
        font-weight: 500;
        font-size: 0.16rem;
        p.textItem {
          line-height: 0.26rem;
          margin-bottom: 0.1rem;
        }
        p.textItem.indent {
          text-indent: 0.3rem;
        }
      }
      ul.list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0.1rem;
        li {
          width: 32%;
          margin: 0.1rem 0;
          text-align: center;
          img {
            width: 80%;
            margin: auto;
          }
        }
        li:nth-child(3n + 3) {
          width: 36%;
          img {
            width: 90%;
            margin: auto;
          }
        }
      }
      div.workEnv {
        height: auto;
        position: relative;
        img {
          width: 100%;
          height: auto;
          transform: scale(1);
          transition: all 0.5s;
          cursor: pointer;
          border-radius: 0.1rem;
        }
        div.text {
          text-align: left;
          color: #606266;
          font-weight: 500;
          font-size: 0.16rem;
          position: absolute;
          top: 1.4rem;
          left: 0.2rem;
          p.title2 {
            font-size: 0.26rem;
            font-family: "HarmonyOsSansM";
            color: #303133;
            padding: 0.1rem 0;
            font-style: italic;
          }
          p.textItem {
            font-size: 0.16rem;
            font-family: "HarmonyOsSansM";
            color: #303133;
            line-height: 0.2rem;
          }
        }
      }
      div.float {
        div.text {
          text-align: left;
          color: #909399;
          font-weight: 500;
          font-style: 16px;
          padding: 0.2rem 0.2rem;
          p.title2 {
            font-size: 16px;
            font-family: "HarmonyOsSansM";
            color: #2a2e2e;
            font-style: italic;
            padding: 2px 0;
          }
          p.textItem {
            line-height: 20px;
            padding: 2px 0 16px;
            font-size: 14px;
          }
          p.textItem:last-of-type {
            padding: 2px 0 0;
          }
        }
      }
      div.steps {
        background: #fff;
      }
      div.steps,
      div.workEnv,
      div.float,
      div.text {
        padding: 0 0.2rem;
      }
    }
    .van-tab__pane:nth-child(even) .moduel {
      background: #fff;
    }
  }
  // 高德地图
  #mapContainer {
    width: 100%;
    height: 200px;
    background-color: #ffffff;
  }
  // 弹出层
  .van-popup {
    .van-popup__close-icon {
      color: #2a2e2e;
    }
    ul.menu-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      padding: 1rem 0 0;
      li {
        color: #000;
        padding: 0.26rem;
        font-size: 0.16rem;
        font-family: "HarmonyOsSansR";
      }
    }
  }
}
</style>